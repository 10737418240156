import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  FileInfo
 } from '../models';

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor(private http: HttpClient) {}

  /**
 * 取得檔案列表
 */
listFiles(): Observable<FileInfo[]> {
  const url = '/api/File';

  // #region Send Request
  return this.http.get<FileInfo[]>(
    url
    );
    // #endregion
}

  /**
 * 上傳檔案
 * @param files files
 */
uploadFile(
    files?: File[]
  ): Observable<FileInfo[]> {
  const url = '/api/File';

  const formData = new FormData();
  
  // #region FormData Parameter Name: files
  for ( const item of files ) {
    formData.append('files', item);
  }
  // #endregion

  // #region Send Request
  return this.http.post<FileInfo[]>(
    url,
    formData
  );
  // #endregion
}

  /**
 * 刪除檔案
 * @param filename filename
 */
deleteFile(
    filename?: string
  ): Observable<void> {
  let url = '/api/File';

  const queryList = [];

  // #region Query Parameter Name: filename
  if ( filename !== null && filename !== undefined ) {
    queryList.push('filename=' + encodeURIComponent(filename.toString()));
  }
  // #endregion
  
  // Append URL
  if ( queryList.length > 0 ) {
    url += '?' + queryList.join('&');
  }

  // #region Send Request
  return this.http.delete<void>(
    url
    );
    // #endregion
}

}
