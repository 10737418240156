import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { FileService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class FileDeleteFileResolve implements Resolve<any> {

  constructor(private fileService: FileService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.fileService.deleteFile(
      route.data.filename || route.params.filename || route.queryParams.filename
    );
  }
}
