import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Surroundings-Backstage';

  ngOnInit() {
    // if (sessionStorage.getItem('token')) {
    //   Config.defaultOptions = {
    //     headers: {
    //       Authorization: sessionStorage.getItem('token'),
    //     },
    //   };
    // }
  }
}
