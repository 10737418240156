import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { DocumentService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class DocumentUpdateDocumentResolve implements Resolve<any> {

  constructor(private documentService: DocumentService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.documentService.updateDocument(
      route.data.document || route.params.document || route.queryParams.document
    );
  }
}
