import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { CommonService } from './services/common.service';
import {
  LoaderInterceptorService,
  LoaderService
} from './services';
import { LoaderComponent } from './components/loader/loader.component';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgxFullCalendarModule } from 'ngx-fullcalendar';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';

const MATERIAL = [
  MatButtonModule,
  MatIconModule,
  MatSlideToggleModule,
  MatInputModule,
  MatSelectModule,
  MatTabsModule,
  MatTableModule,
  MatDialogModule,
  MatDatepickerModule,
  MatNativeDateModule
];

const SERVICES = [
  LoaderInterceptorService,
  LoaderService,
  CommonService
];

const COMPONENTS = [
  LoaderComponent
];

const MODULES = [CKEditorModule, NgxFullCalendarModule];

@NgModule({
  declarations: [COMPONENTS],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MATERIAL, MODULES],
  exports: [MATERIAL, COMPONENTS, MODULES],
  providers: [
    SERVICES,
    {provide: MAT_DATE_LOCALE, useValue: 'zh-tw'}
  ],
})
export class ThemeModule { }
