import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { FileService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class FileUploadFileResolve implements Resolve<any> {

  constructor(private fileService: FileService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.fileService.uploadFile(
      route.data.files || route.params.files || route.queryParams.files
    );
  }
}
