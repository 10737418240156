import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  UserListItem,
  User,
  LoginInfo
 } from '../models';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) {}

  /**
 * 取得所有使用者清單
 * @param admin 管理員過濾
 */
listUser(
    admin?: boolean
  ): Observable<UserListItem[]> {
  let url = '/api/User';

  const queryList = [];

  // #region Query Parameter Name: admin
  if ( admin !== null && admin !== undefined ) {
    queryList.push('admin=' + encodeURIComponent(admin.toString()));
  }
  // #endregion
  
  // Append URL
  if ( queryList.length > 0 ) {
    url += '?' + queryList.join('&');
  }

  // #region Send Request
  return this.http.get<UserListItem[]>(
    url
    );
    // #endregion
}

  /**
 * 建立新使用者
 * @param user 使用者
 */
createUser(
    user: User
  ): Observable<User> {
  const url = '/api/User';

  // #region Send Request
  return this.http.post<User>(
    url,
    user
  );
  // #endregion
}

  /**
 * 更新使用者資訊
 * @param user 使用者
 */
updateUser(
    user: User
  ): Observable<User> {
  const url = '/api/User';

  // #region Send Request
  return this.http.put<User>(
    url,
    user
  );
  // #endregion
}

  /**
 * 取得指定使用者
 * @param account 帳號
 */
getUser(
    account?: string
  ): Observable<User> {
  let url = '/api/User/account';

  const queryList = [];

  // #region Query Parameter Name: account
  if ( account !== null && account !== undefined ) {
    queryList.push('account=' + encodeURIComponent(account.toString()));
  }
  // #endregion
  
  // Append URL
  if ( queryList.length > 0 ) {
    url += '?' + queryList.join('&');
  }

  // #region Send Request
  return this.http.get<User>(
    url
    );
    // #endregion
}

  /**
 * 更新使用者密碼
 * @param account 帳號
 * @param password 密碼
 */
updatePassword(
    account: string,
    password: string
  ): Observable<void> {
  let url = '/api/User/{account}/password';

  // #region Path Parameter Name: account
  url = url.replace('{account}', account.toString());
  // #endregion

  // #region Send Request
  return this.http.put<void>(
    url,
    password
  );
  // #endregion
}

  /**
 * 刪除指定使用者
 * @param account 帳號
 */
deleteUser(
    account: string
  ): Observable<void> {
  let url = '/api/User/{account}';

  // #region Path Parameter Name: account
  url = url.replace('{account}', account.toString());
  // #endregion

  // #region Send Request
  return this.http.delete<void>(
    url
    );
    // #endregion
}

  /**
 * 登入
 * @param loginInfo 登入資訊
 */
login(
    loginInfo: LoginInfo
  ): Observable<string> {
  const url = '/api/User/login';

  // #region Send Request
  return this.http.post<string>(
    url,
    loginInfo
  );
  // #endregion
}

}
