import { Injectable } from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import { DocumentService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class DocumentListDocumentResolve implements Resolve<any> {

  constructor(private documentService: DocumentService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.documentService.listDocument(
      route.data.collection || route.params.collection || route.queryParams.collection,
      route.data.offset || route.params.offset || route.queryParams.offset,
      route.data.limit || route.params.limit || route.queryParams.limit
    );
  }
}
