import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  IPagingResultOfDocumentListItem,
  Document
 } from '../models';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor(private http: HttpClient) {}

  /**
 * 列出所有文件集合名稱
 */
listDocumentCollection(): Observable<string[]> {
  const url = '/api/Document/collection';

  // #region Send Request
  return this.http.get<string[]>(
    url
    );
    // #endregion
}

  /**
 * 列出指定文件集合中所有文件
 * @param collection 文件集合名稱
 * @param offset 起始索引
 * @param limit 取得筆數
 */
listDocument(
    collection?: string,
    offset?: number,
    limit?: number
  ): Observable<IPagingResultOfDocumentListItem> {
  let url = '/api/Document';

  const queryList = [];

  // #region Query Parameter Name: collection
  if ( collection !== null && collection !== undefined ) {
    queryList.push('collection=' + encodeURIComponent(collection.toString()));
  }
  // #endregion
  
  // #region Query Parameter Name: offset
  if ( offset !== null && offset !== undefined ) {
    queryList.push('offset=' + encodeURIComponent(offset.toString()));
  }
  // #endregion
  
  // #region Query Parameter Name: limit
  if ( limit !== null && limit !== undefined ) {
    queryList.push('limit=' + encodeURIComponent(limit.toString()));
  }
  // #endregion
  
  // Append URL
  if ( queryList.length > 0 ) {
    url += '?' + queryList.join('&');
  }

  // #region Send Request
  return this.http.get<IPagingResultOfDocumentListItem>(
    url
    );
    // #endregion
}

  /**
 * 建立文件
 * @param document 文件
 */
createDocument(
    document: Document
  ): Observable<Document> {
  const url = '/api/Document';

  // #region Send Request
  return this.http.post<Document>(
    url,
    document
  );
  // #endregion
}

  /**
 * 更新文件
 * @param document 文件
 */
updateDocument(
    document: Document
  ): Observable<Document> {
  const url = '/api/Document';

  // #region Send Request
  return this.http.put<Document>(
    url,
    document
  );
  // #endregion
}

  /**
 * 取得指定文件
 * @param id 文件ID
 */
getDocument(
    id: string
  ): Observable<Document> {
  let url = '/api/Document/{id}';

  // #region Path Parameter Name: id
  url = url.replace('{id}', id.toString());
  // #endregion

  // #region Send Request
  return this.http.get<Document>(
    url
    );
    // #endregion
}

  /**
 * 刪除指定文件
 * @param id 文件ID
 */
deleteDocument(
    id: string
  ): Observable<void> {
  let url = '/api/Document/{id}';

  // #region Path Parameter Name: id
  url = url.replace('{id}', id.toString());
  // #endregion

  // #region Send Request
  return this.http.delete<void>(
    url
    );
    // #endregion
}

}
