import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  PageListItem,
  Page
 } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PageService {

  constructor(private http: HttpClient) {}

  /**
 * 列出所有頁面
 */
listPage(): Observable<PageListItem[]> {
  const url = '/api/Page';

  // #region Send Request
  return this.http.get<PageListItem[]>(
    url
    );
    // #endregion
}

  /**
 * 建立頁面
 * @param page 頁面
 */
createPage(
    page: Page
  ): Observable<Page> {
  const url = '/api/Page';

  // #region Send Request
  return this.http.post<Page>(
    url,
    page
  );
  // #endregion
}

  /**
 * 更新頁面
 * @param page 頁面
 */
updatePage(
    page: Page
  ): Observable<Page> {
  const url = '/api/Page';

  // #region Send Request
  return this.http.put<Page>(
    url,
    page
  );
  // #endregion
}

  /**
 * 取得指定頁面
 * @param id 頁面ID
 */
getPage(
    id: string
  ): Observable<Page> {
  let url = '/api/Page/{id}';

  // #region Path Parameter Name: id
  url = url.replace('{id}', id.toString());
  // #endregion

  // #region Send Request
  return this.http.get<Page>(
    url
    );
    // #endregion
}

  /**
 * 刪除指定頁面
 * @param id 頁面ID
 */
deletePage(
    id: string
  ): Observable<void> {
  let url = '/api/Page/{id}';

  // #region Path Parameter Name: id
  url = url.replace('{id}', id.toString());
  // #endregion

  // #region Send Request
  return this.http.delete<void>(
    url
    );
    // #endregion
}

}
