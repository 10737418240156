import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import {
  DocumentService,
  FileService,
  PageService,
  UserService
} from './services';
import {
  DocumentListDocumentCollectionResolve,
  DocumentListDocumentResolve,
  DocumentCreateDocumentResolve,
  DocumentUpdateDocumentResolve,
  DocumentGetDocumentResolve,
  DocumentDeleteDocumentResolve,
  FileListFilesResolve,
  FileUploadFileResolve,
  FileDeleteFileResolve,
  PageListPageResolve,
  PageCreatePageResolve,
  PageUpdatePageResolve,
  PageGetPageResolve,
  PageDeletePageResolve,
  UserListUserResolve,
  UserCreateUserResolve,
  UserUpdateUserResolve,
  UserGetUserResolve,
  UserUpdatePasswordResolve,
  UserDeleteUserResolve,
  UserLoginResolve
} from './resolves';

@NgModule({
  declarations: [],
  imports: [CommonModule, HttpClientModule],
  providers: [
    DocumentService,
    FileService,
    PageService,
    UserService,
    DocumentListDocumentCollectionResolve,
    DocumentListDocumentResolve,
    DocumentCreateDocumentResolve,
    DocumentUpdateDocumentResolve,
    DocumentGetDocumentResolve,
    DocumentDeleteDocumentResolve,
    FileListFilesResolve,
    FileUploadFileResolve,
    FileDeleteFileResolve,
    PageListPageResolve,
    PageCreatePageResolve,
    PageUpdatePageResolve,
    PageGetPageResolve,
    PageDeletePageResolve,
    UserListUserResolve,
    UserCreateUserResolve,
    UserUpdateUserResolve,
    UserGetUserResolve,
    UserUpdatePasswordResolve,
    UserDeleteUserResolve,
    UserLoginResolve
  ]
})
export class SurroundingsModule  {
}
